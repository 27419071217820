import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Card from 'react-bootstrap/Card';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { getCookieValue, setCookie, deleteCookie } from './cookie';

const Input = (props) => {
    const [cookie, updateCookie] = useState(getCookieValue(props.cookieName));

    const onSave = (value) => {
        setCookie(props.cookieName, value);
        updateCookie(value);
    };

    const onClear = () => {
        deleteCookie(props.cookieName);
        updateCookie('');
    };

    return (
        <Card className="flex-grow-1">
            <Card.Header className="d-flex">
                <h5 className="flex-grow-1 mb-0 text-left mr-2">{props.label}</h5>
                <OverlayTrigger
                    overlay={
                        <Popover id={'popover-' + props.label}>
                            <Popover.Body>{props.description}</Popover.Body>
                        </Popover>
                    }>
                    <small
                        className="align-items-center"
                        style={{
                            display: 'inline-flex',
                            marginLeft: 4,
                            position: 'relative',
                            top: '0.05rem'
                        }}>
                        <svg
                            className="bi bi-question-circle-fill"
                            width="1em"
                            height="1em"
                            viewBox="0 0 16 16"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.57 6.033H5.25C5.22 4.147 6.68 3.5 8.006 3.5c1.397 0 2.673.73 2.673 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.355H7.117l-.007-.463c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.901 0-1.358.603-1.358 1.384zm1.251 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z"
                            />
                        </svg>
                    </small>
                </OverlayTrigger>
            </Card.Header>

            <Card.Body data-testid={'toggle-' + props.cookieName}>
                <InputGroup>
                    <FormControl
                        value={cookie || ''}
                        onChange={(e) => onSave(e.target.value)}
                        placeholder={props.placeholder}
                    />
                    {cookie && (
                        <Button variant="warning" onClick={onClear}>
                            Clear
                        </Button>
                    )}
                </InputGroup>
            </Card.Body>
        </Card>
    );
};

export default Input;
