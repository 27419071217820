import React from 'react';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import ToggleCard from './ToggleCard';
import { getCookieValue, setCookie, deleteCookie } from './cookie';

const Toggle = (props) => {
    const cookieValue = getCookieValue(props.cookieName);
    const checked = cookieValue === props.cookieValueWhenOn;

    const toggleCookie = (checked) => {
        if (props.unsetIfOff === true && !checked) {
            deleteCookie(props.cookieName);
        } else {
            const value = checked ? props.cookieValueWhenOn : props.cookieValueWhenOff;
            setCookie(props.cookieName, value);
        }
    };

    return (
        <ToggleCard
            label={props.label}
            description={props.description}
            cookieName={props.cookieName}>
            <BootstrapSwitchButton
                width={75}
                checked={checked}
                onstyle="primary"
                onlabel="On"
                offLabel="Off"
                onChange={(checked) => toggleCookie(checked)}
            />
        </ToggleCard>
    );
};

export default Toggle;
