import React from 'react';
import Input from './Input.jsx';

const Inputs = () => (
    <div className="d-flex flex-column flex-lg-row gap-4">
        <Input
            label="Jessi Stage Branch"
            cookieName="jessi-stage-branch"
            description="Stage Jessi will be served from this subfolder in the S3 bucket, instead of the root. Useful for QA"
            placeholder="jessi-branch-name"
        />

        <Input
            label="Dobby Stage Branch"
            cookieName="dobby-stage-branch"
            description="Stage Dobby will be served from this subfolder in the S3 bucket, instead of the root. Useful for QA"
            placeholder="dobby-branch-name"
        />
    </div>
);

export default Inputs;
