import React from 'react';
import './App.css';
import Toggles from './Toggles.jsx';
import Inputs from './Inputs.jsx';
import Container from 'react-bootstrap/Container';

const App = () => (
    <div className="App">
        <Container>
            <div className="d-flex flex-column gap-5 mt-5">
                <div className="text-center">
                    <h2 className="d-none d-md-inline">Personal Feature Toggles</h2>
                    <h4 className="d-inline d-md-none">Personal Feature Toggles</h4>
                    <p className="d-none d-md-block lead text-left">
                        All toggles shown here only affect your individual browser. Behind every
                        toggle is a cookie which is set or removed for you. Note that there can be
                        global feature toggles which affect the ones defined here. For example, if
                        the Configurator Backend Toggle is deactivated in Fury, setting the Dobby
                        toggle here will have no effect.
                    </p>
                </div>
                <Toggles />

                <div className="text-center">
                    <h2 className="d-none d-md-inline">Branch Deployments</h2>
                    <h4 className="d-inline d-md-none">Branch Deployments</h4>
                    <p className="d-none d-md-block lead text-left">
                        Want to check out changes for a specific branch deployment? Simply add the
                        branch name into one of the inputs below. As with the toggles, a cookie will
                        be set or removed for you for each input you use.
                    </p>
                </div>
                <Inputs />
            </div>
        </Container>
    </div>
);

export default App;
