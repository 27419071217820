export const deleteCookie = (cookieName) => {
    setCookie(cookieName, '', -1);
};

export const setCookie = (cookieName, value, days = 365) => {
    const targetDomain = document.domain.split('.').slice(1).join('.');
    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = `${cookieName}=${value};path=/;domain=${targetDomain};expires=${d.toUTCString()}`;
};

export const getCookieValue = (cookieName) => {
    const v = document.cookie.match('(^|;) ?' + cookieName + '=([^;]*)(;|$)');
    return v ? v[2] : null;
};
