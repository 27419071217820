import React from 'react';
import Toggle from './Toggle.jsx';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const Toggles = () => (
    <Row xs={1} md={2} className="g-4">
        <Col>
            <Toggle
                className="h-100"
                label="Dobby Calendar"
                cookieName="cb-calendar-group-v2"
                cookieValueWhenOff="A"
                cookieValueWhenOn="B"
                unsetIfOff={false}
                description="If switched on, you will be in the Dobby calendar A/B test group. If switched off, you will be in the control group and not get Dobby for calendars at all."
            />
        </Col>
        <Col>
            <Toggle
                className="h-100"
                label="Local Basket"
                cookieName="localBasket"
                cookieValueWhenOn="1"
                unsetIfOff={true}
                description="If switched on, Fury will directly communicate with Magento instead of using the Basket Service."
            />
        </Col>
    </Row>
);

export default Toggles;
